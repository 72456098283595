table {
    border-collapse: collapse;
    margin: auto;
    width: 70%;
    /* max-width: 500px; */
    /* height: 100px; */
  }
  
  td {
    text-align: center;
    border: 2px solid #a9dce3;
    height: 70px;
    width: 70px;
  }

  .fewData:hover{
    background-color: rgb(224, 214, 214);
  }
  
  th {
    border: 2px solid #a9dce3;
    background-color:  #7689de;
    color: white;
    height: 20px;
  }
  
  td {
    /* cursor: pointer; */
  }  
  button {
    background-color: #ffffff;
    color: #fa2609;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    /* cursor: pointer; */
    font-size: 14px;
    margin: 5px;
  }
  button div strong{
    /* color: white; */
    /* z-index: 1000; */
  }
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

.present strong{
    /* background-color: #1f0435; */
    padding: 5px 12px;
    border-radius: 50%;
    color: red;
}

.absent strong{
    /* background-color: rgb(9, 3, 15); */
    padding: 5px 12px;
    /* border-radius: 50%; */
    /* position: absolute; */
    /* top: 0; */
    /* right: "20px"; */
    /* margin: 5px 1px; */
    /* margin-top: 2px; */
    /* margin-right: 50px; */
    color: rgb(255, 0, 0);
}

.attTable .controles{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar td.passed {
  background-color: #8c14aa;
}