.attendance {
    display: flex;
    justify-content: flex-start;
    /* height: 1200px;
    background-color: #fa2609;
    width: 200px; */
}

.attendanceLeft {
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    /* flex-basis: 46%; */
    
}

.attendanceRight {
    /* display: flex;
justify-content: flex-end;
    flex-direction: column;
    background-color: aqua; 
    flex-basis: 54%; */

    width:auto;
    /* background-color: blue; */
}

.attendanceLeft .calendar {
    /* position: fixed;
    width: 40%;
    top: 25%;
    left: 18%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.attendanceLeft .clock {
    display: flex;
    justify-content: space-between;
}

.attendanceLeft .clock button {
    background-color: #ffffff;
    color: #fa2609;
    border: 2px solid #fa2609;
    font-size: 20px;
    padding: 10px 30px;
    transition: .3s;
}

.attendanceLeft .clock button:hover {
    background-color: #fa2609;
    color: #ffffff;
    border: 2px solid #ffffff;
}